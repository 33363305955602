<template>
    <div>
        <div class="uk-padding-small">
            <vk-breadcrumb class="uk-margin-remove">
                <vk-breadcrumb-item :disabled="true">Liste des utilisateurs SUEZ</vk-breadcrumb-item>
            </vk-breadcrumb>
        </div>
        <div class="uk-padding-small">
            <vue-good-table
                mode="remote"
                @on-search="searchValue"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                :isLoading.sync="isLoading"
                :pagination-options="pagination"
                :totalRows="remoteData.totalRecords"
                :rows="remoteData.rows"
                :columns="columns"
                :sort-options="{
                    enabled: false,
                }"
                styleClass="vgt-table striped bordered condensed"
            >
                <template slot="loadingContent">
                    <span class="vgt-loading__content">Téléchargement...</span>
                </template>
                <template v-slot:table-row="props">
                    <div v-if="props.column.field === 'accountType'">
                        <div v-if="props.row.accountType == 'agencyAccount'">Operateur suez</div>
                        <div v-else-if="props.row.accountType == 'superManagerAccount'">Administrateur</div>
                    </div>
                    <div v-else-if="props.column.field === 'actions'" class="text-center">
                        <button type="button" class="btn btn-primary" @click="editUser(props.row.id)">
                            <font-awesome-icon icon="fa-regular fa-eye" />
                        </button>
                    </div>
                </template>
            </vue-good-table>
        </div>
    </div>
</template>

<script>
import store from '@/store'
import { mapActions, mapState } from 'vuex'
import backendApi from '@/backend/api'

export default {
    name: 'UsersSuez',
    data: () => ({
        timeout: null,
        isLoading: false,
        serverParams: {
            columnFilters: {},
            sort: [],
            page: 1,
            perPage: 10,
            searchTerm: '',
        },
        pagination: {
            enabled: true,
            mode: 'records',
            perPage: 10,
            position: 'both',
            perPageDropdown: [10, 25, 50],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            jumpFirstOrLast: true,
            firstLabel: 'Début',
            lastLabel: 'Fin',
            nextLabel: 'Suiv.',
            prevLabel: 'Préc.',
            rowsPerPageLabel: 'Lignes par page ',
            ofLabel: 'de',
            pageLabel: 'page',
            allLabel: 'Tous',
        },
        remoteData: {
            rows: [],
            totalRecords: 0,
        },
    }),
    methods: {
        ...mapActions('users', ['updateSuezUsersList']),
        editUser(userId) {
            this.$router.push({ name: 'user_edit', params: { userId: userId } })
        },
        searchValue(data) {
            this.updateParams({ searchTerm: data.searchTerm })
            if (this.timeout) clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
                this.loadItems()
            }, 1000)
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps)
        },

        onPageChange(params) {
            this.updateParams({ page: params.currentPage })
            this.loadItems()
        },

        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage })
            this.loadItems()
        },
        onSortChange(params) {
            this.updateParams({ sort: params })
            this.loadItems()
        },
        onColumnFilter(params) {
            this.updateParams(params)
            this.loadItems()
        },
        loadItems() {
            this.isLoading = true
            backendApi
                .getSuezUsersList(store.state.login.user.token, this.serverParams)
                .then((data) => {
                    this.remoteData.totalRecords = data.totalRecords
                    this.remoteData.rows = data.rows
                    this.updateParams({
                        page: data.currentPage,
                        perPage: data.maxResult,
                    })
                })
                .catch((e) => {
                    this.isLoading = false
                    this.$store.dispatch('notifications/showWarning', e.response.data.exception.message)
                })
        },
    },
    computed: {
        ...mapState('users', { usersList: 'list', usersListIsUpdating: 'listIsUpdating' }),
        columns: function () {
            return [
                {
                    label: 'Identifiant',
                    field: 'username',
                    filterOptions: {
                        enabled: true,
                    },
                    sortable: true,
                },
                {
                    label: 'Nom',
                    field: 'lastName',
                    filterOptions: {
                        enabled: true,
                    },
                    sortable: true,
                },
                {
                    label: 'Prénom',
                    field: 'firstName',
                    filterOptions: {
                        enabled: true,
                    },
                    sortable: true,
                },
                {
                    label: 'GID',
                    field: 'greenId',
                    filterOptions: {
                        enabled: true,
                    },
                    sortable: true,
                },
                {
                    label: 'Profil',
                    field: 'profile',
                    filterOptions: {
                        enabled: true,
                    },
                    sortable: true,
                },
                {
                    label: 'Domaine',
                    field: 'domain',
                    filterOptions: {
                        enabled: true,
                    },
                    sortable: true,
                },
                {
                    label: 'Statut',
                    field: 'status',
                    filterOptions: {
                        enabled: true,
                    },
                    sortable: true,
                },
                {
                    label: 'Actions',
                    field: 'actions',
                    width: '1%',
                },
            ]
        },
    },
    mounted() {
        this.loadItems()
    },
    beforeRouteEnter(to, from, next) {
        store.dispatch('users/updateSuezUsersList')
        next()
    },
}
</script>

<style scoped></style>
